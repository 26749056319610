import React, { useRef, useEffect } from 'react';
import "../css/countdown.css"
const PriceDisplay = (props) => {
    const daysRef = useRef(null);
    const hoursRef = useRef(null);
    const minutesRef = useRef(null);
    const secondsRef = useRef(null);

    const endDate = new Date(props.until);

    function updateCountdownTime(){
        const currentTime = new Date();
        const diff = endDate - currentTime;

        const d = Math.floor(diff/1000/60/60/24);
        const h = Math.floor(diff/1000/60/60)%24;
        const m = Math.floor(diff/1000/60)%60;
        const s = Math.floor(diff/1000)%60;
        console.log(s)

        daysRef.current.innerText = d;
        hoursRef.current.innerText = h;
        minutesRef.current.innerText = m;
        secondsRef.current.innerText = s;
    }

    useEffect(() => {
        // Update the countdown timer every second
        const intervalId = setInterval(updateCountdownTime, 1000);
    
        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
      });

    let priceAfterDiscount

    if (typeof props.discount === 'string') {
        if (props.discount.includes('$')) {
            let discount = props.discount.replace(/\D/g,'');
            priceAfterDiscount = props.price - discount;

        }
        else if (props.discount.includes('%')) {
            let discount = props.discount.replace(/\D/g,'');
            priceAfterDiscount = props.price - (props.price * discount / 100);
        }
        else {
            let discount = props.discount.replace(/\D/g,'');
            priceAfterDiscount = props.price - discount;
        }
    } else if (props.discount) {
        priceAfterDiscount = props.price - (props.price * props.discount / 100);    
    }

    return (
        <div className="price-wrapper">
            <h2 className='text-center margin-none'><i>{props.desc}</i></h2>
            <div id='countdown' className="countdown">
                <div className="time">
                    <h2 id='days' ref={daysRef}>00</h2>
                    <small>Days</small>
                </div>

                <div className="time">
                    <h2 id='hours' ref={hoursRef}>00</h2>
                    <small>Hours</small>
                </div>

                <div className="time">
                    <h2 id='minutes' ref={minutesRef}>00</h2>
                    <small>Minutes</small>
                </div>

                <div className="time">
                    <h2 id='seconds' ref={secondsRef}>00</h2>
                    <small>Seconds</small>
                </div>
            </div>
            {/* <h2 id='suffix'>Remaining</h2> */}
            {props.price > priceAfterDiscount && priceAfterDiscount > 0 ? <h2><s>${props.price}</s></h2> : null}
            {priceAfterDiscount >= 0 ? 
            <a className='flex' href='https://buy.stripe.com/dR6bK280J1cG9S8288' rel="noopener noreferrer">
                <h1>${priceAfterDiscount}</h1>
            </a>
            : 'Fix your discount... Price is less than 0!' }
        </div>
    );
}

export default PriceDisplay;