import React from 'react';
// import { useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
// console.log("Stripe API Key:", process.env.REACT_APP_STRIPE_KEY);
// const getStripe = () => {
//     if (!stripePromise) {
//         stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
//     }
//     return stripePromise;
// };

function Product(props) {
//     const [stripeError, setStripeError] = useState(null);
//     const [isLoading, setLoading] = useState(false)
//     const item = {
//         price: "price_1NT4dcFBga5iSWHjjVs1WEvC",
//         quantity: 1,
//     }
// const checkoutOptions = {
//     lineItems: [item],
//     mode: "payment",
//     successUrl: `${window.location.origin}/success`,
//     cancelUrl: `${window.location.origin}/cancel`
// }

// const redirectToCheckout = async () => {
//     console.log("redirectToCheckout executed")
//     setLoading(true);
//     const stripe = await getStripe()
//     const { error } = await stripe.redirectToCheckout(checkoutOptions);

//     console.log('stripe_checkout_error', error)

//     if(error) setStripeError(error.message);
//     setLoading(false);

// };

// if (stripeError) alert(stripeError);
    return (
        <div>
            <h1 className='slide-heading'>{props.name}</h1>
            <div className='img-wrapper use-full-width'><img className='product-img' src={props.coverImage} alt='hundred dollar bill custom towel design'/><br /></div>
            <span className='flex'>
                {/* <button className="flex checkout-button" onClick={redirectToCheckout} disabled={isLoading}>{isLoading ? "Loading..." : "Buy it Now"}</button> */}
                <a href='https://buy.stripe.com/dR6bK280J1cG9S8288' rel="noopener noreferrer">
                    <button className='flex checkout-button button-margin'>Buy it Now</button>
                </a>
            </span>
        </div>
    )
}

export default Product;