import '../css/styles.css';
import NavBar from '../components/NavBar';
const Success = () => {
    return(
        <>
        <NavBar moreClasses={'light'}/>
        <div className="order-page">
            <span className="wrapper transf-up">
                <h1 className="headline center">The Order Was a Success</h1>
                <h2 className="notice center">Thank you for your purchase!</h2>
                <form className='marketing-form center'>
                <p className='center'>if you haven't yet, consider subscibing to our promotional e-mail list! we'll update you with new developments in our shop!</p>
                <input className='center' type="text" placeholder='enter your e-mail' /* onChange={handleInputChange} */ /><br/>
                <input className='center' type="submit" id="discountCode" value="subscribe to updates" />
                <p className='center'>(we don't share your data or private info!)</p>
                                <h3 className='center'>Check your e-mail for the order confirmation # and tracking!</h3>
                </form>
            </span>
        </div>
        </>
    )
};

export default Success;