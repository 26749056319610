import '../css/styles.css';
import '../css/checkout.css';
import React, { useState } from 'react';

const Checkout = () => {


    const [isBillingSameAsShipping, setBillingSameAsShipping] = useState(true);

    const handleCheckboxChange = (event) => {
        setBillingSameAsShipping(event.target.checked);
    };






    return (
        <div className='order-page'>
            <div className='cart-wrapper'>

            </div>

            <div className='checkout-wrapper'>
                <form className='checkout-form'>
                    <section id='shipping'>
                        <h1>Pay with card</h1>
                        <h2>Shipping information</h2>
                        <label for='input-email'>E-mail</label><br/>
                        <input type='email' id='input-email'/>
                        <br/>
                        <label for='input-shipping'>Shipping address</label><br/>
                        <input type='text' id='input-shipping' placeholder='Name'/><br/>
                        <select id='country' name='country'>
                            <option value=''>Country</option>
                            <option>Canada</option>
                            <option>Mexico</option>
                            <option>United States</option>
                        </select><br/>
                        <input type='text' id='address-line1' placeholder='Address line 1'/><br/>
                        <input type='text' id='address-line2' placeholder='Address line 2'/><br/>
                        <input type='text' id='city' placeholder='City'/>
                        <input type='number' id='zip-code' placeholder='Zip code'/><br/>
                        <span id='US States'>
                            <select id='us-states' name='state'>
                                <option value=''>State</option>
                            </select>
                        </span><br/>
                    </section>
                    
                    <section id='payment-information'>
                        <h2>Payment details</h2>
                        <label for="cardNumber">Card information:</label><br/>
                        <input type="text" id="cardNumber" name="cardNumber" placeholder="1234 1234 1234 1234" required /><br/>
                        <input type="text" id="expiryDate" name="expiryDate" placeholder="MM/YY" required/><br/>
                        <input type="text" id="cvv" name="cvv" placeholder="CVC" required/><br/>

                        <label for='billing-checkbox'>Billing address is the same as shipping</label>
                        <input type='checkbox'
                        id='billing-checkbox'
                        checked={isBillingSameAsShipping}
                        onChange={handleCheckboxChange}
                        /><br/>
                    </section>

                    {isBillingSameAsShipping ? null : (
                    <section id='billing'>
                        <h2>Billing address</h2>
                        <label for="cardHolder">Name on card:</label><br/>
                        <input type="text" id="cardHolder" name="cardHolder" placeholder="" required/><br/>
                        <label for='input-shipping'>Shipping address</label><br/>
                        <input type='text' id='input-shipping' placeholder='Name'/><br/>
                        <select id='country' name='country'>
                            <option value=''>Country</option>
                            <option>Canada</option>
                            <option>Mexico</option>
                            <option>United States</option>
                        </select><br/>
                        <input type='text' id='address-line1' placeholder='Address line 1'/><br/>
                        <input type='text' id='address-line2' placeholder='Address line 2'/><br/>
                        <input type='text' id='city' placeholder='City'/>
                        <input type='number' id='zip-code' placeholder='Zip code'/><br/>
                        <span id='US States'>
                            <select id='us-states' name='state'>
                                <option value=''>State</option>
                            </select>
                        </span><br/>
                    </section>
                          )}

                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default Checkout;