
import NavBar from '../components/NavBar';
import '../css/styles.css';
const Cancel = () => {
    return(
        <>
        <NavBar moreClasses={'light'}/>
        <div className="order-page">
            <span className="wrapper transf-up">
                <h1 className="headline center">Order Cancellation</h1>
                <h2 className="notice center">We're sorry to see you go!</h2>
                <form className='marketing-form center'>
                <p className='center'>But wait! if you subscibe to our promotional e-mail list, we'll give you 15% off this order!</p>
                <input className='center' type="text" placeholder='enter your e-mail' /* onChange={handleInputChange} */ /><br/>
                <input className='center' type="submit" id="discountCode" value="receive discount" />
                <p className='center'>The discount code will be sent to the e-mail you provided.
                <br/>(we don't share your data or private info!)</p>
                </form>
            </span>
        </div>
        </>
    )
};

export default Cancel;