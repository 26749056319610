import React from 'react';
import { useState, useRef, useEffect } from 'react';
import '../css/styles.css';

const NavBar = ({moreClasses, footer, setFooterBtnDisabled}) => {
    const ddIconRef = useRef(null)
    const dropDownMenuRef = useRef(null)
    const linkSelectionRef = useRef(null)

        //toggle Dropdown
        const [ddIsToggled, setDdIsToggled] = useState(true)

        const handleClick = () => {
        if(window.scrollY === 0) {
            setDdIsToggled(!ddIsToggled);
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        };
    
        //mouseenter on dropdown (DOESN"T DO ANYTHING)
        const handleMouseEnter = () => {
            if(window.scrollY === 0) {
                setDdIsToggled(false);
        }
        };


        //Dynamic Navigation Styles code (NavStyles)
        useEffect(() => {
            const rects = document.getElementsByClassName("dd-icon-rect");
            let hrElements;
            if (footer && footer.current) {
                hrElements = footer.current.querySelectorAll("hr");
                }
            
            // Dropdown Icon Click: Scroll the page to the top
                // ddIconRef.current.svgElement.addEventListener('click', () => {
                //   window.scrollTo({ top: 0, behavior: 'smooth' });
                // });
                
            // Add scroll event listener
            const handleScroll = () => {
                const scrollPositionFromTop = window.pageYOffset || document.documentElement.scrollTop;
                const scrollPositionFromBottom = document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
            
                  // Define the desired scroll threshold
                const scrollThreshold = 100; // Change this value as needed
            
                // Check if the scroll position is below the threshold
              if (scrollPositionFromTop > scrollThreshold) {
                // Add a class to the element to change its color
                dropDownMenuRef.current.classList.add("dim");
                for (const rect of rects) {
                    rect.setAttribute("fill", "#00000050");
                  }
              } else {
                // Remove the class if the scroll position is above the threshold
                dropDownMenuRef.current.classList.remove("dim");
                linkSelectionRef.current.classList.add("toggled");
                for (const rect of rects) {
                    rect.setAttribute("fill", "black");
                  }
              }
              
              if (footer && scrollPositionFromBottom > scrollThreshold) {
                // Add a class to the element to change its color
                footer.current.classList.add("dim");
                for (const hrElement of hrElements) {
                    hrElement.style.backgroundColor = "#0000002b";
                  }
                setFooterBtnDisabled(true)
              } else if (footer){
                // Remove the class if the scroll position is above the threshold
                for (const hrElement of hrElements) {
                    hrElement.style.backgroundColor = "black";
                  }
                footer.current.classList.remove("dim");
                setFooterBtnDisabled(false)
              }
            };
            
            
            window.addEventListener('scroll', handleScroll);
            
            return () => {
                  window.removeEventListener('scroll', handleScroll);
              };
            });

    return (
        <>
            
        <span id="dropdown-menu" ref={dropDownMenuRef} className={moreClasses}>
            <span className='nav-wrapper'><a className='page-title' href='/'> moneytowels</a>{" "}
                <svg id='dropdown-icon' ref={ddIconRef} onClick={handleClick} onMouseEnter={handleMouseEnter} xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                    <rect className='dd-icon-rect' width="24" height="4" rx="2" fill="black"/>
                    <rect className='dd-icon-rect' y="8" width="24" height="4" rx="2" fill="black"/>
                    <rect className='dd-icon-rect' y="16" width="24" height="4" rx="2" fill="black"/>
                </svg>
            </span>
        </span>
        <div ref={linkSelectionRef} className={`select ${ddIsToggled ? 'toggled' : ''}`}>
            <ul className="menu">
                <li className='nav-link active'><a href='/'>Home</a></li>
                <li className='nav-disclaimer active'>We'll have more pages soon. Subscribe to our email list for updates!</li>
                {/* <li>Order Tracking</li> */}
                <li className='right long-text'>email: contact@moneytowels.net</li>
            </ul>
        </div>

        </>
    );
}

export default NavBar;