import { useEffect } from "react";

function ChangeBackground ({slideWrapper, intersectionOne}) {
    useEffect(() => {
    const intersectionOneOptions = {
        root: null, // Use the browser viewport as the root element
        rootMargin: "0px", // No margin around the root element
        threshold: 0.8, // Trigger the callback when 50% of the element is in view
      };
    const intersectionOneObserver = new IntersectionObserver(function(
        entries,
        intersectionOneObserver
    ) {
        entries.forEach(entry => {
            // console.log(entry.target);
            if(!entry.isIntersecting){
                console.log("it's intersecting!")
                slideWrapper.current.classList.toggle("background2")

            }
            else {
                console.log("it's not intersecting!")
                slideWrapper.current.classList.remove("background2")
            }
        })

    }, intersectionOneOptions)

    intersectionOneObserver.observe(intersectionOne.current);

        // Cleanup function
        return () => {
            intersectionOneObserver.disconnect();
          };
},[slideWrapper, intersectionOne]);
}
export default ChangeBackground