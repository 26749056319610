import "@stripe/stripe-js"
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Landing from './pages/Landing';
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import Checkout from "./pages/Checkout";
import Tracking from "./pages/Tracking";
import TtPixel from "./components/TtPixel";


function App() {
  return (
  <div>
    <Router>
      <Routes>
        <Route index element={<Landing />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="success" element={<Success />} />
        <Route path="cancel" element={<Cancel />} />
        <Route path="tracking" element={<Tracking />} />
      </Routes>
    </Router>
    <TtPixel/>
  </div>
  );
}

export default App;
