import '../css/styles.css';
const Tracking = () => {
    return(
        <div className="order-page">
            <span className="wrapper transf-up">
                <h2 className="notice center">Order tracking</h2>
                <form className='track-order-form'>
                <p className='center'>Order tracking is currently e-mail only. Check your e-mail for tracking details!</p>
                <input className='center' type="text" placeholder='Tracking id #' disabled /* onChange={handleInputChange} */ /><br/>
                <input className='center' type="submit" id="discountCode" value="Track Order" disabled />
                <p className='center'>This page is coming Soon...</p>
                </form>
            </span>
        </div>
    )
};

export default Tracking;