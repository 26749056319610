import React from 'react';
import { useState, useRef } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
import ChangeBackground from '../components/IntersectionObserver';
import '../css/styles.css';
import '../css/grids.css'
import '../assets/fonts'
import'../css/dropdown.css';
import NavBar from '../components/NavBar';
import Product from '../components/Product';
import moneyTowelImg from '../assets/hundred beach towel mockup.png'
// import moneyRobeImg from '../assets/moneyrobeproduct.png'

import img1 from "../assets/usecase1.jpg"
import img2 from "../assets/usecase2.jpg"
import img3 from "../assets/usecase3.png"
import PriceDisplay from '../components/PriceDisplay';

// let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
// console.log("Stripe API Key:", process.env.REACT_APP_STRIPE_KEY);
// const getStripe = () => {
//     if (!stripePromise) {
//         stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
//     }
//     return stripePromise;
// };

const Landing = () => {
    //Exit button closes page
    const handleExitClick = () => {
        window.location.href = 'about:blank';
      };

            //toggle footerButton
const [footerInView, setFooterBtnDisabled] = useState(false);

    //DropdownMenu Elements
    const footer = useRef(null)

    //Get Intersections
    const slideWrapper = useRef(null)
    const intersectionOne = useRef(null)
    const intersectionTwo = useRef(null)
    const intersectionThree = useRef(null)

    //Stripe
//     const [stripeError, setStripeError] = useState(null);
//     const [isLoading, setLoading] = useState(false)
//     const item = {
//         price: "price_1NT4dcFBga5iSWHjjVs1WEvC",
//         quantity: 1,
//     }
// const checkoutOptions = {
//     lineItems: [item],
//     mode: "payment",
//     successUrl: `${window.location.origin}/success`,
//     cancelUrl: `${window.location.origin}/cancel`
// }

// const redirectToCheckout = async () => {
//     console.log("redirectToCheckout executed")
//     setLoading(true);
//     const stripe = await getStripe()
//     const { error } = await stripe.redirectToCheckout(checkoutOptions);

//     console.log('stripe_checkout_error', error)

//     if(error) setStripeError(error.message);
//     setLoading(false);

// };

// if (stripeError) alert(stripeError);
return (
    <div>
        <ChangeBackground slideWrapper={slideWrapper} intersectionOne={intersectionOne}/>


        <NavBar footer={footer} setFooterBtnDisabled={setFooterBtnDisabled}/>


        <div className='slide-container slider'>
            <section className='slides' ref={slideWrapper}>

                    <div id='slide1-row1'ref={intersectionOne} className='row-1 canvas'>
                        <Product name='"Hundred" Beach Towel' coverImage={moneyTowelImg}/>
                    </div>

                    <div id='slide1-row2' className='row-2 canvas'>
                        <span className='flex-rows'>
                            <h1 className='slide-heading'>Benefits</h1>   
                            <span id='row1'>Stand out at the beach, the spa or the pool. Made from soft, water-absortbent micro-fiber  </span>
                            <span id='row2'>Looks great on a towel rack, or across your other "rack," ladies!  </span>
                            <span id='row3'>If not used for the beach can be used to wrap clothes, large amounts of cash or gifts stylishly  </span>
                            <span id='row4'>Can be used as a shower rug, room decoration or wall ornament  </span>
                        </span>
                        <Product name='' coverImage={moneyTowelImg}/>
                    </div>

                    <div id="slide1-row3" ref={intersectionTwo} className='flex row-3 canvas'>
                    <span className='flex canvas-child'>
                    <h1 className='canvas-child slide-heading'>Wow</h1>
                    <span className="fill-line"><img className='canvas-child' src={img1} alt='four ladies on a beach with their legs in the air'/><img className='canvas-child' src={img2} alt='woman at spa'/><img className='canvas-child' src={img3} alt='A wall-mounted hundred-dollar bill decoration'/></span>
                    <p className='canvas-child'>Stand out at the beach, the spa or the pool. Made from soft, water-absortbent micro-fiber. You're sure to attract compliments from friends and onlookers with this unique piece of drapery.</p>   
                    </span>
                    </div>

                    <div id='slide1-row4' ref={intersectionThree} className='row-4 canvas dvh-top-margin'>
                        <span className='faq-grid'>
                            <h1 className='slide-heading'>FAQ</h1>   
                            <span id='faq1'><b>What are the product's dimensions?</b> 140" x 70" (inches)</span>
                            <span id='faq2'><b>How fast is shipping?</b> We are currently working that out since the product is currently being manufactured. It will be available in August to ship.</span>
                            <span id='faq3'><b>What if the product comes damaged, or I don't like it?</b> 
                            <span className='long-text'>We understand. That's why we offer a 14-day money-back guarantee on our beach towels! And accept returns on all products! Our goal is that you leave a happy customer.</span> 
                            <span className='short-text'> We offer a 14-day money-back guarantee on our beach towels.</span>
                            </span>
                            <span id='faq4'><b>How can I trust moneytowels.com?</b> 
                            <span className='long-text'>We are commited to delivering you the best money-themed products and service. For that reason, we have a daily monitored support e-mail. We will get back to you within 24-48 hours with the intention to solve your problem, not dismiss it.</span>
                            <span className='short-text'> We are commited to delivering you the best money-themed products and service. For that reason, we have a daily monitored support e-mail that you can contact. We will get back to you within 24-48 hours.</span>
                            </span>
                            <span id='faq5'><b>What are the main cons of this product?</b>
                            <span className='long-text'>We'd agree that the biggest con of a custom beach towel like this is that when you're lying on it, you'll cover the design obviously, so people are less likely to see it! It typically looks great though when you wear it around yourself.</span>
                            <span className='short-text'>When you're lying on the beach, you'll cover the design obviously, so people will be less able to see it! That's pretty much it.</span>                            
                            </span>
                        </span>
                        <a className='flex button-container button-margin' href='https://buy.stripe.com/dR6bK280J1cG9S8288' rel="noopener noreferrer">
                            <button className='flex checkout-button'>Buy it Now - $30</button>
                        </a>
                        <PriceDisplay until={'September 21 2023 00:00:00'} desc={'Summer discount (17% off)'} price={30} discount={'5'}/>

                    </div>
            </section>
        </div>
        <div id='footer' className='footer' ref={footer}>
            <div className='page-ending-break'><hr/><span>end of site</span><hr/></div>
            <span>We also have a 14-day money back guarantee! (returns accepted)</span><br />
            <button className='exit-btn classico' onClick={handleExitClick} disabled={footerInView}>No thanks, Exit this website</button>
            <ul>
                <li>our support email: <br />contact@moneytowels.net</li>
            </ul>
        </div>
    </div>
)
}

export default Landing;